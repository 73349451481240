import * as React from "react"
import HeaderBar from "../components/HeaderBar"
import FooterBar from "../components/FooterBar"

const SkillsPage = () => {
    return (
        <div>
            {HeaderBar()}
            <h1>Skills</h1>
            <div>
                <p>
                    An index, of sorts, of skills with back-links to projects where these skills are more applied.
                </p>
            </div>
            <h2>Frameworks &amp; Libraries</h2>
            <div>
                <ul>
                    <li>Asyncio (Python): <a href="/facebook">Facebook</a></li>
                    <li>BeautifulSoup (Python - HTML parsing): various minor web-scraping projects</li>
                    <li>Django (Python - backend web framework): <a href="/solidworks">SolidWorks Webapp</a> <a href="/current">SCARA Arm Webapp</a></li>
                    <li>Gatsby (Static Site Generator): <a href="/website">This Website</a></li>
                    <li>Google Test (Unit Testing, primarily C/C++): as a <a href="/taassignment">TA</a> we used Google Test for many testing programs</li>
                    <li>Google RPC (Cross-Language Remote Procedure Calls): <a href="/tinysns">CSCE 438 - TinySNS</a></li>
                    <li>Make (Build System): Most of my C/C++ projects use make as well as being a learning objective when I was a Teacher's Assistant</li>
                    <li>React (Front End Framework): <a href="/website">This Website</a></li>
                    <li>Py Unittest (Unit Testing, Python): Used in various projects</li>
                    <li>Windows API: <a href="/wikipedia">CSCE 313 - Wikipedia Indexer</a></li>
                    <li>Windows COM (C#): <a href="/solidworks">SolidWorks API</a></li>
                </ul>
                <div>
                    See also <a href="/facebook">Facebook</a> for technologies used during my internships with Facebook
                </div>
            </div>
            <h2>Programming Languages</h2>
            <div>
                <ul>
                    <li>C/C++: <a href="/wikipedia">CSCE 313 - Wikipedia Indexer</a> <a href="/urs">CSCE 491 - Undergraduate Research</a> <a href="/polypack">CSCE 649 - GPU Polygon Packing</a></li>
                    <li>C#: <a href="/solidworks">Working with C# and the SolidWorks API</a></li>
                    <li>Python: <a href="/adventofcode">Advent of Code</a> <a href="/vexu">VexU Co-Pro</a></li>
                    <li>Haskell: When I started as a TA I did Sunday Reviews for the CSCE 314 course (which covers Haskell)</li>
                    <li>Markdown: <a href="/autoformatter">TA Grading Formatter</a> and several homeworks (export with Pandoc)</li>
                    <li>Matlab: <a href="/marblesorter">Marble Sorter</a> and other homeworks throughout the years</li>
                    <li>Web: <a href="/sumobots">Sumobots</a> <a href="/cs462">CSCE 462 Project</a></li>
                </ul>
            </div>
            <h2>CAD/CAM</h2>
            <div>
                <ul>
                    <li>Cura: (for 3D Printers) I guess technically its CAM software</li>
                    <li>Autodesk Fusion 360: primary CAM package I've utilized</li>
                    <li>Autodesk Inventor: I used their API briefly while working on <a href="/polypack">GPU Packing Project</a></li>
                    <li>RDWorks: (for Laser Cutters) if Cura counts as CAM then this should too I guess</li>
                    <li>SolidWorks: <a href="/12dprinter">12d Printer</a> <a href="/scara">Scara Arm</a> - Certified SolidWorks Processional (2019)</li>
                </ul>
            </div>
            <h2>Machining</h2>
            <div>
                <ul>
                    <li>3D Printing: <a href="/scara">SCARA Robot</a> <a href="/12dprinter">12d Printer</a></li>
                    <li>Injection Molding: <a href="/kidkraft">KidKraft</a></li>
                    <li>Laser Cutting: <a href="/frc">FRC</a> and some parts of my job at KidKraft and SwIRL</li>
                    <li>Milling: <a href="/frc">FRC</a></li>
                    <li>Turning: <a href="/frc">FRC</a></li>
                </ul>
            </div>
            {FooterBar()}
        </div>
    )
}

export default SkillsPage